<template>
    <b-card no-body class="border-primary">
      <!-- Plan Header -->
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">Current Plan</h5>
        <b-badge variant="light-primary">
          {{ currentPlan.membership_type_name || subscription.planId }}
        </b-badge>
        
      </b-card-header>
  

      
      <b-card-body>
        <!-- Subscription Status -->
        <!-- <div class="mb-2"> -->
          <!-- <small class="text-muted">Status</small> -->
          <!-- <div class="d-flex align-items-center mt-1"> -->
            <!-- <b-badge :variant="getStatusVariant">
              {{ subscription.status }}
            </b-badge> -->
            <!-- <span v-if="subscription.status === 'trialing'" class="ml-1 small">
              (Expires {{ formatDate(subscription.trialEndsAt) }})
            </span> -->
          <!-- </div> -->
        <!-- </div> -->
  
        <!-- Usage Statistics -->
        <!-- <div class="mb-2">
          <small class="text-muted">Monthly Usage</small> -->
          
          <!-- Image Runs Usage -->
          <!-- <div class="mt-1">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <span class="small">Image Runs</span>
              <small>{{ subscription.usage.imageCreations }} / {{ subscription.limits.imageCreations }}</small>
            </div>
            <b-progress 
              :value="calculateProgress(subscription.usage.imageCreations, subscription.limits.imageCreations)"
              height="0.5rem"
              class="mb-1"
            />
          </div> -->
  
          <!-- Products Upload Usage -->
          <!-- <div class="mt-2">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <span class="small">Products Upload</span>
              <small>{{ subscription.usage.imageProcessing }} / {{ subscription.limits.imageProcessing }}</small>
            </div>
            <b-progress 
              :value="calculateProgress(subscription.usage.imageProcessing, subscription.limits.imageProcessing)"
              height="0.5rem"
              class="mb-1"
            />
          </div> -->
        <!-- </div> -->
  
        <!-- Plan Benefits -->
        <div class="mt-2">
          <!-- <small class="text-muted">Plan Benefits</small> -->
          <ul class="list-unstyled my-1">
            <li v-for="(benefit, index) in currentPlan.benefit_lists" :key="index">
              <span class="spaced-item align-middle">
                <feather-icon
                  icon="CheckIcon"
                  size="14"
                  class="mr-1 text-primary"
                />
                {{ benefit.benefit_description }}
              </span>
            </li>
          </ul>
        </div>
  
        <!-- Upgrade Button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="upgradePlan"
        >
          Upgrade Plan
        </b-button>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BProgress,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  
  export default {
    directives: {
      Ripple,
    },
    
    components: {
      BCard,
      BCardHeader,
      BCardBody,
      BBadge,
      BButton,
      BProgress,
    },
  
    data() {
      return {
        membershipDetails: [
          {
            membership_type_id: 'starter',
            membership_type_name: "Starter",
            membership_price: "0",
            membership_type_image_url: require('@/assets/images/illustration/Pot1.svg'),
            benefit_lists: [
              { benefit_description: "40 image creations (4 images/run)" },
              { benefit_description: "Unlimited text creation" },
              { benefit_description: "1 product image processing" },
            ]
          },
          {
            membership_type_id: 'professional',
            membership_type_name: "Professional",
            membership_price: "49",
            membership_type_image_url: require('@/assets/images/illustration/Pot2.svg'),
            benefit_lists: [
              { benefit_description: "400 image creations (4 images/run)" },
              { benefit_description: "Unlimited text creation" },
              { benefit_description: "5 product image processing" },
            ]
          },
          {
            membership_type_id: 'business',
            membership_type_name: "Business",
            membership_price: "99",
            membership_type_image_url: require('@/assets/images/illustration/Pot3.svg'),
            benefit_lists: [
              { benefit_description: "1,000 image creations (4 images/run) " },
              { benefit_description: "Unlimited text creation" },
              { benefit_description: "20 product image processing" },
            ]
          },
        ],
        userData: null,
        loading: true,
        error: null,
      }
    },
  
    computed: {
      subscription() {
        return this.userData?.subscription || {
          planId: 'starter',
          status: '',
          usage: { imageCreations: 0, imageProcessing: 0 },
          limits: { imageCreations: 25, imageProcessing: 1 }
        }
      },
  
      currentPlan() {
        return this.membershipDetails.find(plan => 
          plan.membership_type_id === this.subscription.planId
        ) || this.membershipDetails[0]
      },
  
      getStatusVariant() {
        const statusMap = {
          active: 'success',
          trialing: 'info',
          cancelled: 'danger',
          incomplete: 'warning'
        }
        return `light-${statusMap[this.subscription.status] || 'primary'}`
      }
    },
  
    created() {
      this.fetchUserData()
    },
  
    methods: {
      async fetchUserData() {
        try {
          const userData = localStorage.getItem('userData')
          if (!userData) {
            this.$router.push('/index')
            return
          }
          
          const parsedUserData = JSON.parse(userData)
          const response = await fetch(`${process.env.VUE_APP_BASE_URL}/getUser/${parsedUserData.userId}`)
          const result = await response.json()
          
          if (result.success) {
            this.userData = result.data
          } else {
            this.error = 'Failed to load user data'
          }
        } catch (error) {
          console.error('Error fetching user data:', error)
          this.error = 'An error occurred while loading user data'
        } finally {
          this.loading = false
        }
      },
  
      calculateProgress(used, limit) {
        return (used / limit) * 100
      },
  
      formatDate(dateString) {
        return new Date(dateString).toLocaleDateString()
      },
  
      upgradePlan() {
        this.$router.push('/plans')
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .spaced-item {
    display: inline-block;
    margin-bottom: 8px;
  }
  
  .progress {
    background-color: rgba(115, 103, 240, 0.12);
    .progress-bar {
      background-color: #7367f0;
    }
  }
  
  .border-primary {
    border-color: #7367f0 !important;
  }
  
  .text-primary {
    color: #5aa842 !important;
  }
  </style>