<template>
    <div class="subscription-container pb-7">
      <b-row class="match-height">
        <!-- User Info Column -->
        <b-col cols="12" xl="9" lg="8" md="7">
          <profile-user-info />
        </b-col>
  
        <!-- User Plan Column -->
        <b-col cols="12" md="5" xl="3" lg="4">
          <user-plan />
        </b-col>
      </b-row>
  
      <!-- New Usage Statistics Row -->
      <b-row class="mt-2 match-height">
        <!-- Subscription Status Card -->
        <b-col cols="12" lg="6">
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                <feather-icon
                  icon="ActivityIcon"
                  size="18"
                  class="mr-2"
                />
                Current Subscription
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col sm="6">
                  <div class="mb-2">
                    <small class="">Plan</small>
                    <h4 class="text-capitalize mb-0">{{ subscription.planId }}</h4>
                  </div>
                </b-col>
                <b-col sm="6">
                  <div class="mb-2 mt-0">
                    <small class="">Status</small>
                    <h4 class="text-capitalize mb-0">{{ subscription.status }}</h4>
                  </div>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col sm="6">
                  <div class="mb-2">
                    <small class="">Billing Start</small>
                    <h5 class="mb-0">{{ formatDate(subscription.currentPeriodStart) }}</h5>
                  </div>
                </b-col>
                <b-col sm="6">
                  <div class="mb-2">
                    <small class="">Billing End</small>
                    <h5 class="mb-0">{{ formatDate(subscription.currentPeriodEnd) }}</h5>
                  </div>
                </b-col>
              </b-row>
              
              <b-alert
                v-if="subscription.status === 'trialing'"
                show
                variant="info"
                class="mt-2"
              >
                <feather-icon
                  icon="AlertCircleIcon"
                  size="16"
                  class="mr-2"
                />
                Trial ends on {{ formatDate(subscription.trialEndsAt) }}
              </b-alert>
            </b-card-body>
          </b-card>
        </b-col>
  
        <!-- Usage Overview -->
        <b-col cols="12" lg="6">
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                <feather-icon
                  icon="BarChartIcon"
                  size="18"
                  class="mr-2"
                />
                Usage Overview
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <!-- Image Creation Usage -->
              <div class="mb-3">
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <div>
                    <feather-icon
                      icon="ImageIcon"
                      size="16"
                      class="mr-1"
                    />
                    <span>Images Created</span>
                  </div>
                  <small>{{ subscription.usage.imageCreations }} / {{ subscription.limits.imageCreations  }}</small>
                </div>
                <b-progress
                  :value="calculateProgress(subscription.usage.imageCreations, subscription.limits.imageCreations)"
                  height="0.5rem"
                  :class="getProgressBarClass(subscription.usage.imageCreations, subscription.limits.imageCreations)"
                />
              </div>
  
              <!-- Image Processing Usage -->
              <div>
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <div>
                    <feather-icon
                      icon="ZapIcon"
                      size="16"
                      class="mr-1"
                    />
                    <span>Images Processed</span>
                  </div>
                  <small>{{ subscription.usage.imageProcessing }} / {{ subscription.limits.imageProcessing }}</small>
                </div>
                <b-progress
                  :value="calculateProgress(subscription.usage.imageProcessing, subscription.limits.imageProcessing)"
                  height="0.5rem"
                  :class="getProgressBarClass(subscription.usage.imageProcessing, subscription.limits.imageProcessing)"
                />
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
</template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAlert,
    BProgress,
  } from 'bootstrap-vue'
  import ProfileUserInfo from './ProfileUserInfo.vue'
  import UserPlan from './UserPlan.vue'
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      BAlert,
      BProgress,
      ProfileUserInfo,
      UserPlan,
    },
  
    data() {
      return {
        userData: null,
        loading: true,
        error: null,
      }
    },
  
    computed: {
        subscription() {
      return this.userData?.subscription || {
        planId: '',
        status: '',
        usage: { imageCreations: 0, imageProcessing: 0 },
        limits: { imageCreations: 0, imageProcessing: 0 },
        billingStartDate: null,
        billingEndDate: null
      }
    },
    },
  
    created() {
      this.fetchUserData()
    },
  
    methods: {
        async fetchUserData() {
      try {
        const userData = localStorage.getItem('userData')
        if (!userData) {
          this.$router.push('/index')
          return
        }
        
        const parsedUserData = JSON.parse(userData)
        const response = await fetch(`${process.env.VUE_APP_BASE_URL}/getUser/${parsedUserData.userId}`)
        const result = await response.json()
        
        if (result.success) {
          this.userData = result.data
        } else {
          this.error = 'Failed to load user data'
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
        this.error = 'An error occurred while loading user data'
      } finally {
        this.loading = false
      }
    },

    calculateProgress(used, limit) {
      return (used / limit) * 100
    },

    getProgressBarClass(used, limit) {
      const percentage = (used / limit) * 100
      return {
        'progress-bar-success': percentage < 60,
        'progress-bar-warning': percentage >= 60 && percentage < 85,
        'progress-bar-danger': percentage >= 85
      }
    },

    formatDate(dateString) {
      if (!dateString) return 'N/A'
      const date = new Date(dateString)
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
    }
  }
}
</script>
  
  <style lang="scss" scoped>
 .progress {
  background-color: rgba(115, 103, 240, 0.12);
  
  .progress-bar {
    &.progress-bar-success {
      background-color: #28c76f;
    }
    
    &.progress-bar-warning {
      background-color: #ff9f43;
    }
    
    &.progress-bar-danger {
      background-color: #ea5455;
    }
  }
}

.match-height {
  > [class*='col'] {
    display: flex;
    flex-flow: column;
    > .card {
      flex: 1 1 auto;
    }
  }
}
.subscription-container {
  margin-bottom: 4rem; /* Additional space for the bottom navigation */
}

  </style>