<template>
    <b-card class="user-info">
      <b-row>
        <b-col cols="21" xl="5" class="d-flex justify-content-between flex-column">
          <!-- User Avatar & Info -->
          <div class="d-flex justify-content-start">
            <b-avatar 
              size="104px" 
              variant="light-secondary" 
              rounded 
              :src="userData.photoURL" 
              :text="avatarText(userData.userName)" 
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ userData.userName }}
                </h4>
                <span class="card-text">{{ userData.email }}</span>
              </div>
              <div class="mt-0">
                <b-badge 
                  variant="success" 
                  class="mr-1 text-capitalize"
                >
                  {{ userData.status }}
                </b-badge>
                <!-- <b-badge 
                  :variant="getPlanVariant" 
                  class="text-capitalize"
                >
                  {{ userData.account }}
                </b-badge> -->
              </div>
            </div>
          </div>
        </b-col>
  
        <!-- User Details Table -->
        <b-col cols="12" xl="7">
          <table class="mt-2 mt-xl-0 w-100 user-info-table">
            <tr>
              <th class="pb-50 text-nowrap">
                <feather-icon icon="UserIcon" class="mr-75 hidden-sm" />
                <span class="font-weight-bold">User Name:</span>
              </th>
              <td class="pb-50">
                {{ userData.userName }}
              </td>
            </tr>
            <!-- <tr>
              <th class="pb-50 text-nowrap">
                <feather-icon icon="HashIcon" class="mr-75 hidden-sm" />
                <span class="font-weight-bold">User ID:</span>
              </th>
              <td class="pb-50">
                {{ userData.userId }}
              </td>
            </tr> -->
            <!-- <tr>
              <th class="pb-50 text-nowrap">
                <feather-icon icon="CheckIcon" class="mr-75 hidden-sm" />
                <span class="font-weight-bold">Account Status:</span>
              </th>
              <td class="pb-50 text-capitalize" :class="{'text-success': userData.status === 'active'}">
                {{ userData.status }}
              </td>
            </tr> -->
            <tr>
              <th class="pb-50 text-nowrap">
                <feather-icon icon="CalendarIcon" class="mr-75 hidden-sm" />
                <span class="font-weight-bold">Member Since:</span>
              </th>
              <td class="pb-50">
                {{ formatDate(userData.createdAt) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 text-nowrap">
                <feather-icon icon="ZapIcon" class="mr-75 hidden-sm" />
                <span class="font-weight-bold">Total Runs:</span>
              </th>
              <td class="pb-50">
                {{ userData.totalExecutionCount || 0 }}
              </td>
            </tr>
        
            <!-- <tr v-if="userData.country !== 'Unknown'">
              <th class="pb-50 text-nowrap">
                <feather-icon icon="MapPinIcon" class="mr-75 hidden-sm" />
                <span class="font-weight-bold">Location:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.city !== 'Unknown' ? `${userData.city}, ` : '' }}{{ userData.country }}
              </td>
            </tr> -->
            <tr>
              <th class="pb-50 text-nowrap">
                <feather-icon icon="SaveIcon" class="mr-75 hidden-sm" />
                <span class="font-weight-bold">Saved Agents:</span>
              </th>
              <td class="pb-50">
                {{ userData.savedAgents?.length || 0 }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 text-nowrap">
                <feather-icon icon="LogInIcon" class="mr-75 hidden-sm" />
                <span class="font-weight-bold">Login Method:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.loginMethod }}
              </td>
            </tr>
            <tr v-if="showSubscriptionInfo">
              <th class="pb-50 text-nowrap">
                <feather-icon icon="ClockIcon" class="mr-75 hidden-sm" />
                <span class="font-weight-bold">Trial Ends:</span>
              </th>
              <td class="pb-50">
                {{ formatDate(userData.subscription?.trialEndsAt) }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import { BCard, BRow, BCol, BAvatar, BBadge } from 'bootstrap-vue'
  import { avatarText } from '@core/utils/filter'
  
  export default {
    name: 'ProfileUserInfo',
    
    components: {
      BCard,
      BRow,
      BCol,
      BAvatar,
      BBadge
    },
  
    data() {
      return {
        userData: {
          userId: '',
          email: '',
          userName: '',
          photoURL: '',
          status: '',
          createdAt: '',
          account: '',
          plan: '',
          loginMethod: '',
          country: 'Unknown',
          city: 'Unknown',
          totalExecutionCount: 0,
          savedAgents: [],
          subscription: null
        }
      }
    },
  
    computed: {
      getPlanVariant() {
        const planVariants = {
          starter: 'light-info',
          professional: 'light-warning',
          business: 'light-success'
        }
        return planVariants[this.userData.plan] || 'light-primary'
      },
  
      showSubscriptionInfo() {
        return this.userData.subscription?.status === 'trialing'
      }
    },
  
    created() {
      this.fetchUserData()
    },
  
    methods: {
      async fetchUserData() {
        try {
          const storedUserData = localStorage.getItem('userData')
          if (!storedUserData) {
            this.$router.push('/index')
            return
          }
  
          const parsedStoredData = JSON.parse(storedUserData)
          const response = await fetch(`${process.env.VUE_APP_BASE_URL}/getUser/${parsedStoredData.userId}`)
          const result = await response.json()
          
          if (result.success) {
            this.userData = result.data
          }
        } catch (error) {
          console.error('Error fetching user data:', error)
        }
      },
  
      formatDate(dateString) {
        if (!dateString) return '-'
        return new Date(dateString).toLocaleDateString()
      },
  
      avatarText
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .user-info {
    .badge {
      font-size: 0.875rem;
      padding: 0.4rem 0.6rem;
    }
    
    .hidden-sm {
      @media (max-width: 576px) {
        display: none;
      }
    }
  
    .user-info-table {
      th {
        width: 40%;
        @media (min-width: 1200px) {
          width: 35%;
        }
      }
    }
  }
  </style>